import Styles from './Services.module.css'
import { Link } from 'react-router-dom';

function Services () {

  const cardHeight = "125px";
  const borderRadius = "15px";

  return (
    <div>
      <div className='content mb-0 mt-3'>
        <div className='row mb-0'>
          <div className={'col-6 ' + Styles.CardPaddingRight}>
            <Link to='/data'>
              <div className='card card-style mx-0 mb-2 p-3' style={{borderRadius: borderRadius, margin: '-7px 15px 30px 15px', height: cardHeight}}>
                <h6 className='font-13 text-center'>Data Topup</h6>
                <h3 className='color-red-dark mb-0 text-center pt-2'>
                  <img src='/dashboard/images/wifi_yellow.png' style={{ width: '68px', boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", padding: "5px", borderRadius: "50%" }} />
                </h3></div>
            </Link>
          </div>


          <div className={'col-6 ps-1 ' + Styles.CardPaddingLeft}>
          <Link to='/airtime'>
            <div className='card card-style mx-0 mb-2 p-3' style={{borderRadius: borderRadius, margin: '-7px 15px 30px 15px', height: cardHeight}}>
              <h6 className='font-13 text-center'>Airtime Topup</h6>
              <h3 className='color-green-dark mb-0 text-center pt-2'><img src='/dashboard/images/phone-call.png' style={{ width: '68px',  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", padding: "5px", borderRadius: "50%" }}/></h3>
            </div>
            </Link>
          </div>


          <div className={'col-6 pe-1 ' + Styles.CardPaddingRight}>
            <Link to='/exam'>
            <div className='card card-style mx-0 mb-2 p-3' style={{borderRadius: borderRadius, margin: '-1px 15px 30px 15px', height: cardHeight}}>
              <h6 className='font-13 text-center'>Result Checker</h6>
              <h3 className='color-red-dark mb-0 text-center pt-2'><img src='/dashboard/images/graduation-cap-congratulation.png' style={{ width: '68px', boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", padding: "5px", borderRadius: "50%" }}/></h3>
            </div>
            </Link>
          </div>


          <div className={'col-6 ps-1 ' + Styles.CardPaddingLeft}>
            {/* <a href="https://mapsventures.com"> */}
            <Link to='/data-card'>
            <div className='card card-style mx-0 mb-2 p-3' style={{borderRadius: borderRadius, margin: '-1px 15px 30px 15px', height: cardHeight}}>
              <h6 className='font-13 text-center'>Data Card</h6>
              <h3 className='color-green-dark mb-0 text-center pt-2'><img src='/dashboard/images/data-pin.png' style={{ width: '68px', height: '68px', boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", padding: "5px", borderRadius: "50%" }}/></h3>
            </div>
            </Link>
            {/* </a> */}
          </div>


          <div className={'col-6 pe-1 ' + Styles.CardPaddingRight}>
          <Link to='/recharge-pin'>
            <div className='card card-style mx-0 mb-2 p-3' style={{borderRadius: borderRadius, margin: '-1px 15px 30px 15px', height: cardHeight}}>
              <h6 className='font-13 text-center'>Recharge Card</h6>
              <h3 className='color-green-dark mb-0 text-center pt-2'><img src='dashboard/images/printer_.png' style={{ width: '68px', height: '68px', boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", padding: "5px", borderRadius: "50%" }}/></h3>
            </div>
          </Link>
          </div>


          <div className={'col-6 ps-1 ' + Styles.CardPaddingLeft}>
          <a href='https://api.whatsapp.com/send/?phone=+2348160788744&text=Hi,%20I%20want%20to%20buy%20BTC'>
            <div className='card card-style mx-0 mb-2 p-3' style={{borderRadius: borderRadius, margin: '-1px 15px 30px 15px', height: cardHeight}}>
              <h6 className='font-14 text-center'>Buy & Sell BTC</h6>
              <h3 className='color-green-dark mb-0 text-center pt-2'><img src='dashboard/images/bitcoin-logo.png' style={{ width: '68px', height: '68px', boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", padding: "5px", borderRadius: "50%" }}/></h3>
            </div>
            </a>
          </div>

          
        </div>
      </div>

      

      <div className='content mb-0' style={{margin: '-1px 15px 30px 15px'}} >
        <div className='d-flex'>
        </div>
        <div className='row mb-0'>

          <div className={'col-6 pe-1 ' + Styles.CardPaddingRight}>
          <Link to='/cable'>
            <div className={'card card-style mx-0 ' + Styles.card_image_bg} style={{borderRadius: borderRadius, backgroundImage: 'url(dashboard/images/multichoice-banner.jpg)', height: '150px', backgroundSize: 'cover' }}>
              <div className='card-bottom p-3' style={{ left: '-5px' }}>
                <h2 className='color-white font-15 text-center'>Cable</h2>
                <p className='color-white opacity-90 line-height-s font-11'>
                  Enjoy an instantly activated Subscriptions on your GOTV, DSTV, SHOWMAX & STARTIMES now!!!.
                </p>
              </div>
              <div className={'card-overlay bg-gradient opacity-30 ' + Styles.serviceGradient}></div>
              <div className='card-overlay bg-gradient'></div>
            </div>
            </Link>
          </div>

          <div className={'col-6 ps-1 ' + Styles.CardPaddingLeft}>
          <Link to='/airtime-cash'>
            <div className={'card card-style mx-0 mb-0 ' + Styles.card_image_bg} style={{borderRadius: borderRadius, backgroundImage: 'url(dashboard/images/recharge-card-business.jpg)', height: '150px', backgroundSize: 'cover' }}>
              <div className='card-bottom p-3' style={{ left: '-5px' }}>
                <h2 className='color-white font-15 text-center'>Airtime To Cash 💰</h2>
                <p className='color-white opacity-90 line-height-s font-11'>
                  Convert airtime loaded in error or excess airtime back to cash now!!!. Payment 💯% is swift
                </p>
              </div>
              <div className={'card-overlay bg-gradient opacity-30 ' + Styles.serviceGradient}></div>
              <div className='card-overlay bg-gradient'></div>
            </div>
            </Link>
          </div>

          
        </div>
      </div>








      {/* -------------------------Electricity------------------------------------ */}
      <div className={'card card-style shadow-bg shadow-bg-m ' + Styles.electricityCard}>
        <div style={{ lineHeight: '60px', borderBottom: 'solid 1px rgba(0,0,0,.05)' }}>
          <span className='text-center' style={{ fontSize: '14px', color: '#1f1f1f', marginLeft: '40px', fontWeight: '500' }}>Electricity Bills</span>
          <i className='fa font-20 fa-lightbulb' style={{ float: 'right', marginRight: '15px', lineHeight: '64px', color: '#ff9800' }}></i>
        </div>
        <div className={'content mb-0 ' + Styles.electricityContentMargin}>
        </div>
        <div className='content mt-2 mb-n3'>
          <div className='row' style={{ justifyContent: 'space-around' }}>


            <div className={'col-6 pe-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.ikedc}>IKEDC</span> </div>
                <img src='/dashboard/images/ikeja-electric.jpg' alt='img' style={{ margin: '20px 0px 0px 0px', width: '67px' }} className='mx-auto' /></span>
                </Link>
            </div>


            <div className={'col-6 ps-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.ibedc}>IBEDC</span> </div>
                <img src='/dashboard/images/ibedc-logo.png' alt='img' style={{ margin: '36px 0px 18px 0px', width: '60px' }} className='mx-auto' />
                </span>
              </Link>
            </div>


            <div className={'col-6 pe-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.jed}>JED</span> </div>
                <img src='/dashboard/images/jos-electric.jpg' alt='img' style={{ margin: '20px 0px 0px 0px', width: '65px'}} className='mx-auto' /></span>
                </Link>
            </div>



            <div className={'col-6 ps-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className='bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl'>PHED</span> </div>
                <img src='/dashboard/images/port-harcourt.jpg' alt='img' style={{ margin: '20px 0px 0px 0px', width: '66.5px' }} className='mx-auto' /></span>
                </Link>
            </div>



            <div className={'col-6 pe-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.aedc}>AEDC</span> </div>
                <img src='/dashboard/images/Abuja-Electric.jpg' alt='img' style={{ margin: '20px 0px 0px 0px', width: '67px' }} className='mx-auto' /></span>
                </Link>
            </div>


            <div className={'col-6 ps-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.kaedco}>KAEDCO</span> </div>
                <img src='/dashboard/images/Kaduna-Electric.jpg' alt='img' style={{ margin: '20px 0px 0px 0px', width: '67px' }} className='mx-auto' /></span>
                </Link>
            </div>


            <div className={'col-6 pe-2 col-lg-3 ' + Styles.bills_col_width}>
              <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.ekedc}>EKEDC</span> </div>
                <img src='/dashboard/images/eko-electric.jpg' alt='img' style={{ margin: '40px 0px 14px 0px', width: '67px' }} className='mx-auto' /></span>
                </Link>
            </div>



            <div className={'col-6 ps-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.kedco}>KEDCO</span> </div>
                <img src='/dashboard/images/Kano-Electric.jpg' alt='img' style={{ margin: '20px 0px 0px 0px', width: '67px' }} className='mx-auto' /></span>
                </Link>
            </div>



            <div className={'col-6 ps-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.eedc}>EEDC</span> </div>
                <img src='/dashboard/images/EEDC-Logo.jpeg' alt='img' style={{ margin: '40px 0px 12.5px 0px', borderRadius: '5px', width: '65px' }} className='mx-auto' /></span>
                </Link>
            </div>





            </div>
          </div>
          
        </div>











      {/* ------------------------- Desktop service display ------------------------------------ */}
      {/* <div className={'card card-style shadow-bg shadow-bg-m col-lg-6 ' + Styles.electricityCard}>
        <div style={{ lineHeight: '60px', borderBottom: 'solid 1px rgba(0,0,0,.05)' }}>
          <span className='text-center' style={{ fontSize: '14px', color: '#1f1f1f', marginLeft: '40px', fontWeight: '500' }}> Our Services </span>
          <i className='fa font-20 fa-lightbulb' style={{ float: 'right', marginRight: '15px', lineHeight: '64px', color: '#ff9800' }}></i>
        </div>
        <div className={'content mb-0 ' + Styles.electricityContentMargin}>
        </div>
        <div className='content mt-2 mb-n3'>
          <div className='row' style={{ justifyContent: 'space-around' }}>


            <div className={'col-6 pe-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.ikedc}>Buy Data</span> </div>
                <img src='/dashboard/images/data.jpg' alt='img' style={{ margin: '20px 0px 0px 0px', width: '67px' }} className='mx-auto' /></span>
                </Link>
            </div>


            <div className={'col-6 ps-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.ibedc}>IBEDC</span> </div>
                <img src='/dashboard/images/airtime.svg' alt='img' style={{ margin: '36px 0px 18px 0px', width: '60px' }} className='mx-auto' />
                </span>
              </Link>
            </div>


            <div className={'col-6 pe-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.jed}>JED</span> </div>
                <img src='/dashboard/images/graduation-cap.png' alt='img' style={{ margin: '20px 0px 0px 0px', width: '65px'}} className='mx-auto' /></span>
                </Link>
            </div>



            <div className={'col-6 ps-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className='bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl'>PHED</span> </div>
                <img src='/dashboard/images/spectranet-device.png' alt='img' style={{ margin: '20px 0px 0px 0px', width: '66.5px' }} className='mx-auto' /></span>
                </Link>
            </div>



            <div className={'col-6 pe-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.aedc}>AEDC</span> </div>
                <img src='/dashboard/images/Abuja-Electric.jpg' alt='img' style={{ margin: '20px 0px 0px 0px', width: '67px' }} className='mx-auto' /></span>
                </Link>
            </div>


            <div className={'col-6 ps-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.kaedco}>KAEDCO</span> </div>
                <img src='/dashboard/images/Kaduna-Electric.jpg' alt='img' style={{ margin: '20px 0px 0px 0px', width: '67px' }} className='mx-auto' /></span>
                </Link>
            </div>


            <div className={'col-6 pe-2 col-lg-3 ' + Styles.bills_col_width}>
              <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.ekedc}>EKEDC</span> </div>
                <img src='/dashboard/images/eko-electric.jpg' alt='img' style={{ margin: '40px 0px 14px 0px', width: '67px' }} className='mx-auto' /></span>
                </Link>
            </div>



            <div className={'col-6 ps-2 col-lg-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.kedco}>KEDCO</span> </div>
                <img src='/dashboard/images/Kano-Electric.jpg' alt='img' style={{ margin: '20px 0px 0px 0px', width: '67px' }} className='mx-auto' /></span>
                </Link>
            </div>



            <div className={'col-6 ps-3 ' + Styles.bills_col_width}>
            <Link to='/electricity'>
              <span className='card card-style mx-0 mb-3' style={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px' }}>
                <div className='card-top m-2'> <span className={'bg-blue-dark font-11 px-2 py-1 font-700 rounded-xs shadow-xxl ' + Styles.eedc}>EEDC</span> </div>
                <img src='/dashboard/images/EEDC-Logo.jpeg' alt='img' style={{ margin: '40px 0px 12.5px 0px', borderRadius: '5px', width: '65px' }} className='mx-auto' /></span>
                </Link>
            </div>





            </div>
          </div>
          
        </div> */}








      <Link to={"/bulk-sms"} className={"card card-style alert " + Styles.bulkSMSCard}>
      <div className="d-flex py-0">
      <div>
      <i className="fas fa-sms me-3 scale-box fa-4x color-blue-dark"  style={{ fontSize: "45px" }}></i>
      </div>
      <div>
      <p className="color-highlight mb-n1 font-12 font-600">Bulk SMS</p>
      <p className="mb-0 font-13 font-400" style={{ lineHeight: "18px" }}>Send message to your friends and family at low cost</p>
      </div>
      <div className="ms-auto align-self-center">
      <div><i className="fa fa-arrow-right font-16 color-red-dark"></i></div>
      </div>
      </div>
      </Link>






      </div>

  )
}

export default Services
