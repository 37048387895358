import axios from 'axios';
import { useReducer, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Styles from './Styles.module.css';
import { baseURL, date, logOff, storage, toaster, user} from '../functions/O3developer';
import { ToastContainer } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';
import PageTopBar from '../components/PageTopBar';
import { PulseLoader } from 'react-spinners';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

function Profile() {
  const navigate = useNavigate();

  const [hook, setHook] = useState('');
  const [value, setValue]  = useState('');
  const [hookLoading, dispatchHookLoading] = useReducer(loadingReducer, false);
  const [deleteLoading, dispatchDeleteLoading] = useReducer(loadingReducer, false);
  const [loading, dispatch] = useReducer(loadingReducer, false);

  const fetchProfile = async () => {
    dispatch({type: 'start'});
      await axios.post(baseURL+"/account/profile",  {
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      }, 
  }).then((response) => {
      if (response.data.status == "failed" && response.data.message !== "") {
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
          // console.log(response.data);
            setHook(response.data.data.url);
              setValue(response.data.data);                
              dispatch({type: 'stop'});
          } else {
            return toaster("error", error.loading.excemption);    
          }

      }).catch((e)=>{
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          
        return toaster("error", error.loading.excemption);
          // return e;
      })
  }


  function saveHook(e) {
    e.preventDefault();  
    if (hook !== "" && hook.length > 5) {        
    dispatchHookLoading({type: 'start'});
    axios.post(baseURL+"/webhook/save",  {
      url: hook,
  }, {    
    headers:{
        'Authorization':`Bearer ${user().token}`,
        'Content-Type': 'application/json'
    }, 
  }).then((response) => {
        // console.log(response.data);
      if (response.data.status == "failed" && response.data.message !== "") {
          dispatchHookLoading({type: 'stop'});
          return toaster("error", response.data.message);
      } else if (response.data.status == "success") { 
          dispatchHookLoading({type: 'stop'});
          return toaster("success", response.data.message);                      
          } else {
            return toaster("error", error.loading.excemption);    
          }
    }).catch((e) => {   
      if (e.response !== undefined) {
        if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
            storage("remove", "data", null, "localStorage");
            return navigate("/login");
        }
      }
        return toaster("error", error.loading.excemption);
        // return e;
    })

  } else {
    return toaster("error", error.validation.requiredInput);
  }

  }


  function deleteUser(e) {
    e.preventDefault()
    const prompt = window.confirm("Select OK to confirm account deletion");
    console.log("testing");
    if (prompt) {
    dispatchDeleteLoading({type: 'start'});
    axios.post(baseURL+"/account/delete",  {}, {    
    headers:{
        'Authorization':`Bearer ${user().token}`,
        'Content-Type': 'application/json'
    }, 
  }).then((response) => {
        // console.log(response.data);
      if (response.data.status == "failed" && response.data.message !== "") {
          dispatchDeleteLoading({type: 'stop'});
          return toaster("error", response.data.message);
      } else if (response.data.status == "success") { 
          dispatchDeleteLoading({type: 'stop'});          
          logOff();
          return toaster("success", response.data.message);                      
          } else {
            return toaster("error", error.loading.excemption);    
          }
    }).catch((e) => {   
      if (e.response !== undefined) {
        if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
            storage("remove", "data", null, "localStorage");
            return navigate("/login");
        }
      }
        return toaster("error", error.loading.excemption);
        // return e;
    })
  } else {
    alert("Thank you for staying with us!");
  }



  }







  useEffect(() => fetchProfile(), []);

  return (
      <div>    
  
      <PageTopBar title="Profile"/>


    <div className="page-title-clear"></div>
    <div className="page-content">

    <ToastContainer /> 

    <div className={"card dashboard-layout card-style mb-0 " + Styles.profile_card_border} style={{ borderTopLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
    <div className="d-flex content mb-1">

    <div className="flex-grow-1">
    <h2 className='font-18'> {user().username.toUpperCase()} <i className="fa fa-check-circle color-blue-dark font-18 mt-2 ms-3"></i></h2>
    <p className="mb-0 mt-1 me-3"> <b> First Name: </b> {user().firstname} </p>
    <p className="mb-0 mt-0 me-3"> <b> Last Name: </b> {user().lastname} </p>
    <p className="mb-0 mt-0 me-3"> <b> Phone Number: </b> {user().phone_number} </p>

    </div>

    <img src="/dashboard/images/user-icon.png" width="80" height="80" className="rounded-circle mt-3 shadow-xl" />
    </div>

    <div className="content mb-0 mt-2">
      <div className="row mb-0">
      <div className="col-6">
      <Link to="/change-password" className="btn btn-full btn-sm rounded-s font-600 font-13" style={{ color: "white", backgroundColor: "red" }}>Reset Password </Link>
      </div>
      <div className="col-6">
      <Link to="/kyc" className="btn btn-full btn-sm rounded-s font-600 font-13" style={{ color: "white", backgroundColor: "green" }}>Verify Account</Link>
      </div>
      </div>
    </div>
    



    <div className="divider mt-4 mb-0"></div>

    <div className="">
      <div style={{ margin: "10px" }}>
      <p className="mb-0 mt-0 me-3" style={{ wordBreak: 'break-all' }}> <b> Email Address: </b> {user().email} </p>

      </div>
 
    </div>

    </div>



<div className='mt-3'></div>

<div className="card card-style dashboard-layout" style={{ borderTopLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
<div className="content">


{ loading ? <SkeletonTheme height={40}> <Skeleton count={3}/> </SkeletonTheme> : null }

<div hidden={loading ? true : false}>

<div className='row mb-0'>

<p className='mb-0 font-13 font-600'>Webhook URL (Optional)</p>

<div className="col-8">
      <div className="input-style has-borders no-icon input-style-always-active mb-4">
      <div className="col-lg-12 input-style has-borders has-icon validate-field mb-4">
      <i className="fa fa-globe" style={{ color: "#663399" }}></i>
      <input type="text" style={{ height: '47px' }} onChange={(e) => setHook(e.target.value)} autoComplete="off" value={hook} className="form-control" id="hook" placeholder="Enter webhook url" />
      <i className="fa fa-times disabled invalid color-red-dark"></i>
      <i className="fa fa-check disabled valid color-green-dark"></i>
      <em></em>
  </div>

      </div>
    </div>



     <div className="col-4">
        <a href="#" onClick={(e) => saveHook(e)} className="btn btn-full btn-m rounded-s font-600" style={{ border: '1px solid #663399', color: '#fff', background: '#663399' }}> 
        { hookLoading ? <PulseLoader color={"#fff"} loading={true} size={8} /> : '' }
        { hookLoading ? '' : "Save "  }
        { hookLoading ? '' : <i className='fas fa-save'></i>  }

        </a>
    </div>
  </div>



  <table className='table table-striped table-bordered mb-3' style={{ width:'100%', marginBottom: '0px' }}>
        <tbody>
          <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"><b> Category </b></span></td>
          <td style={{ fontSize: '12px' }}>  <b> {value.category} </b> </td>
        </tr>

        <tr>
            <td style={{ fontSize: '12px' }}> Reg Date </td>
            <td style={{ fontSize: '12px' }}> { date(value.regDate) }  </td>
        </tr> 


        <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"><b> Status</b></span></td>
          <td style={{ fontSize: '12px' }}>  <b> {value.status} </b> </td>
        </tr>

        <tr>
            <td style={{ fontSize: '12px' }}> API Key </td>
            <td style={{ fontSize: '12px', wordBreak: 'break-all' }}> {user().apikey} <CopyToClipboard text={user().apikey}
          onCopy={() => toaster("success", "API key successfully copied to clipboard")}>
             <span className="ms-2 badge" style={{cursor: "context-menu", border: "1px solid #ff5722", color: "#ff5722", }}> Copy API Key</span></CopyToClipboard>  </td>
        </tr> 
    
      </tbody>
    </table>


    <p className='p-1 m-0 text-dark' style={{fontSize: '12px'}}>NB: Deleting your account will permanently revoke your access to example.com and its services</p>
    <div className="col-6" style={{ display: "flex", justifyContent: "flex-end" }}>
      <a href="#" onClick={deleteUser} className="btn btn-full btn-sm rounded-s font-600 font-13" style={{ color: "white", backgroundColor: "red" }}>
        { deleteLoading ? <PulseLoader color={"#fff"} loading={true} size={8} /> : '' }
        { !deleteLoading ? 'Delete account' : '' }
        
      </a>
      </div>



</div>

</div>
</div>






    </div>
      </div>
    )
  
  }
  
  export default Profile;
  